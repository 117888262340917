import React from 'react'
import { Link } from 'gatsby'

const Footer = () => (
  <div style={{textAlign: 'center', backgroundColor: '#333', padding: '1em', color: '#fff'}}>
    © 2018 Bright Fox, LLC. | <Link to="/terms">Terms of Use</Link> | <Link to="/privacy">Privacy Policy</Link>
  </div>
)

export default Footer
