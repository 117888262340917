import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

 export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 475) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

const IndexImages = (props) => (
  <StaticQuery
    query={graphql`
      query {
        default_red_yellow_green: file(relativePath: { eq: "default_red_yellow_green.png" }) {
          ...fluidImage
        },
        historical_bar_graph: file(relativePath: { eq: "historical_bar_graph.png" }) {
          ...fluidImage
        },
        redeem_rewards_conditions: file(relativePath: { eq: "redeem_rewards_conditions.png" }) {
          ...fluidImage
        },
        do_bonus_tasks_conditions: file(relativePath: { eq: "do_bonus_tasks_conditions.png" }) {
          ...fluidImage
        },
        rating_dialog: file(relativePath: { eq: "rating_dialog.png" }) {
          ...fluidImage
        },
        reward_types: file(relativePath: { eq: "reward_types.png" }) {
          ...fluidImage
        },
        payday_dialog: file(relativePath: { eq: "payday_dialog.png" }) {
          ...fluidImage
        },
        currency_transactions_page: file(relativePath: { eq: "currency_transactions_page.png" }) {
          ...fluidImage
        },
        child_dashboard: file(relativePath: { eq: "child_dashboard.png" }) {
          ...fluidImage
        },
        household_task_option: file(relativePath: { eq: "household_task_option.png" }) {
          ...fluidImage
        },
        force_exact_rotation: file(relativePath: { eq: "force_exact_rotation.png" }) {
          ...fluidImage
        }
      }
    `}
    render={data => <Img fluid={data[props.image].childImageSharp.fluid} style={{border: '1px solid #666'}} />}
  />
)

export default IndexImages
