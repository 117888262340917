import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import Header from './header'
import './layout.css'
import Image from './image'
import Footer from './footer'

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            { name: 'description', content: 'Home chore management, rotation, rewards system, virtual bank account. Getting kids to understand the rewards of a clean home.' },
            { name: 'keywords', content: 'chores,kids,rewards,virtual bank accoount,home organization' },
            { name: 'google-site-verification', content: '15djuHcqWsuypdgfMDvXtizw6vPCjg5VYzbZ5-4UnRU' },
          ]}
        >
          <html lang="en" />
        </Helmet>
        <Header siteTitle={<div><Image /><h2 style={{display: 'inline-block', color: '#fff7f3', verticalAlign: 'top', marginLeft: '1rem'}}>Bright Fox Home</h2></div>} />
        <div
          style={{
            margin: '0 auto',
            maxWidth: 960,
            padding: '0px 1.0875rem',
            paddingTop: 0,
          }}
        >
          {children}
        </div>
        <Footer />
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
