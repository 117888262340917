import React from 'react'

import Layout from '../components/layout'
import Pricing from '../components/pricing'

import Quiz from '../components/quiz';
import IndexImages from '../components/indexImages';

const IndexPage = () => (
  <Layout>
    <div style={{textAlign: 'center'}}>
      <Quiz />
      <h1 className="section-header">BFH Pricing Plans</h1>
      <Pricing />
      <br/><br/><br/><br/><br/>
      <h1 className="section-header">BFH Features</h1>
      <ul className="item-list features col">
        <li>Input all the household chores that need to be completed</li>
        <li>Make sure a chore doesn't get forgotten</li>
        <li>Track each family members' chore completion rates</li>
        <li>Adjust how to reach red/yellow/green status each day</li>
      </ul>
      <ul className="item-list features col">
        <li>Adjust conditions for redeeming rewards or doing bonus tasks</li>
        <li>Set up simple webhooks for a reward to automate some reward redemptions</li>
        <li>Chores can be automatically rotated or assigned based on several settings</li>
        <li>Kids can convert points into currency in the virtual bank account</li>
      </ul>
      <br/><br/><br/>
      <div style={{
        textAlign: 'left',
        backgroundColor: '#eee',
        padding: '4%',
        marginBottom: '2em',
        borderRadius: '1em',
      }}>
        <h1 className="section-header">Using Bright Fox Home</h1>
        <p>
          Every family (like yours) is unique, so a single program for home organization must be flexible enough
          to accomodate many different family situations. While Bright Fox Home cannot accomodate every situation, it works for many.
          And while it doesn't do everything, it is helpful enough that it was worth publishing to the world.
        </p>
        <h2>System Definitions</h2>
        <p>
          The information below will explain how the Bright Fox Home system works, which will help you understand the successful use strategies further down the page.
        </p>
        <ul>
          <li>
            <strong>Chore</strong>
            <ul>
              <li>A chore is something that must be done for the household to "function properly" (you decide what that means for your family).</li>
              <li>Chores can be scheduled daily or on one or more specific days each week.</li>
              <li>With each chore, we define who is able to complete it. This could be a single person, multiple people, any child, any parent, or anybody in the family.</li>
              <li>Chores can have limited availability based on time of day (ie. the chore must be completed before 10:00am or can only be completed after 5:00pm).</li>
              <li>Each time a chore is scheduled, one person is assigned that chore for the day.</li>
              <li>Chores are automatically assigned to distribute workload evenly (if multiple people are available to complete it).</li>
              <li>A chore can override the automatic distribution to be evenly rotated between those in the list.</li>
              <li>We save an estimated time of completion and a number of points to be earned upon full completion with each chore.</li>
              <li>Upon completion, each chore is rated by a parent.</li>
              <li>Chore completion for each child is measured over time and graphed.</li>
              <li>Each chore can be enabled or disabled at any time.</li>
              <li><em>Example of a chore: Wash the dishes after dinner.</em></li>
            </ul>
          </li>
          <li>
            <strong>Household Chore</strong>
            <ul>
              <li>A household chore is simply a chore with an option selected to allow anybody in the list to complete the chore.</li>
              <li>It's either a first-come first-serve or a family negotiation - depending on the dynamics in your family.</li>
              <li>The person to complete the household chore receives points.</li>
              <li>We have the option to set reward redemption being dependent on household chores being sufficiently completed.</li>
              <li>Household chores are similar to bonus tasks, but the difference is that bonus tasks do not have scheduling capability and may be completed daily by all on the list.</li>
            </ul>
            <div style={{maxWidth: 475}}>
              <IndexImages image="household_task_option" />
            </div>
          </li>
          <li>
            <strong>Bonus Task</strong>
            <ul>
              <li>A bonus task is something that would be nice if it were done, but there is no inconvenience to the household if it is not completed.</li>
              <li>With each bonus task, we define who is able to complete it. This could be a single person, multiple people, any child, any parent, or anybody in the family.</li>
              <li>Each bonus task saves a number of points to be earned upon proper completion.</li>
              <li>Each person able to complete a bonus task can complete it each day.</li>
              <li>Upon completion, each bonus task is rated by a parent.</li>
              <li>Each bonus task can be enabled or disabled at any time.</li>
              <li>Bonus tasks are similar to household chores, but the difference is that household chores can be scheduled and be completed only once based on the schedule, whereas a bonus task can be completed by all on the list each day.</li>
            </ul>
          </li>
          <li>
            <strong>Reward</strong>
            <ul>
              <li>A reward can be anything that a child is willing to work for.</li>
              <li>With each reward, we define who is able to redeem it. This could be a single person, multiple people, any child, any parent, or anybody in the family.</li>
              <li>We have the option to limit redeeming rewards based on chore completion.</li>
              <li>Rewards should be strategically created to balance point cost with level of effort to earn points for each child.</li>
              <li>If rewards are too hard to earn, chores and bonus tasks will probably be completed less often.</li>
              <li>Most rewards will be manually fulfilled by a parent.</li>
              <li>A special "currency" reward is available to turn points into currency in the virtual bank accoount.</li>
              <li>A special "webhook" reward is available to turn points into automated fulfillment by smart home systems or other web-connected systems.</li>
              <li>Each reward can be enabled or disabled at any time.</li>
            </ul>
            <div style={{maxWidth: 475}}>
              <IndexImages image="reward_types" />
            </div>
          </li>
          <li>
            <strong>Ratings</strong>
            <ul>
              <li>After a chore or bonus task is completed, a parent must rate it for points to be awarded.</li>
              <li>Rating is from 0.5 to 6 stars, and half-stars are allowed.</li>
              <li>Awarded points are calculated based on the maximum points defined in the task and the rating given.</li>
              <li>Maximum points are awarded for a 6-star rating.</li>
              <li>The stars are color-coded (red, yellow, green), and red stars (0-2) award no points.
                  2.5 - 6 stars award points proportional to the rating. (ex. 3 stars awards 50% of max points, 4 stars awards 67% of max points).</li>
            </ul>
            <div style={{maxWidth: 300}}>
              <IndexImages image="rating_dialog" />
            </div>
          </li>
          <li>
            <strong>Points</strong>
            <ul>
              <li>Points are as valuable or worthless as you make them based on chore, bonus task, and reward values.</li>
              <li>Children can earn points by completing chores and bonus tasks (and having them rated positively).</li>
              <li>Parents can manually add or subtract points from a child with a description of the manual entry.</li>
              <li>A point ledger is available for the last 90-days of point accumulation for each child.</li>
              <li>Points can be traded for rewards.</li>
            </ul>
            <div style={{maxWidth: 475}}>
              <IndexImages image="child_dashboard" />
            </div>
          </li>
          <li>
            <strong>Virtual Bank Account</strong>
            <ul>
              <li>Rewards can be set up to automatically exchange different amounts of points for currency.</li>
              <li>Parents can manually deposit or withdraw currency from a child's account at any time.</li>
              <li>A currency ledger is available for the last 90-days for each child.</li>
            </ul>
            <div style={{maxWidth: 475}}>
              <IndexImages image="currency_transactions_page" />
            </div>
          </li>
        </ul>


        <h2>Successful Use Strategies</h2>

        <h3>Red / Yellow / Green Indicators</h3>
        <p>The red / yellow / green indicator concept is not new. It's easy for any person -
          even young children - to understand that green is where we to be and that red should be avoided.
        </p>
        <div style={{maxWidth: 475}}>
          <IndexImages image="default_red_yellow_green" />
        </div>
        <ul>
          <li>With default settings, each child will start the day at red until they receive 50% of the available points.</li>
          <li>At 50% chore points received, they will be in yellow status until they receive at least 80% of available chore points for the day.</li>
          <li>As you start to understand how each family member works with the system
          combined with your personalized chore, bonus task, and rewards setup,
          you'll be able to determine and adjust the thresholds of red, yellow, and green that work for your family or each child.</li>
          <li>If desired, you can remove red or yellow so only two colors are present.</li>
          <li>If desired, you can set different chore completion thresholds for each color for each child.</li>
        </ul>
        <div style={{maxWidth: 475}}>
          <IndexImages image="historical_bar_graph" />
        </div>
        <ul>
          <li>Each day, you'll see a graph including the last 4 weeks of points achieved by each child and how that maps to red, yellow, or green.</li>
          <li>For a day to show up on the graph, at least one chore for the child must be rated by a parent. A zero-point day may indicate that ratings were not completed.</li>
        </ul>
        <h3>Incomplete and Unrated Chores</h3>
        <p>
          There will be days that children will not complete chores for a variety of reasons.
          There will also days that you may not rate some chores for a variety of reasons.
        </p>
        <ul>
          <li>By default, each incomplete chore reduces the daily points by 50% of the chore's point value.</li>
          <li>You can set the incomplete chore reduction percentage from 0-100% of the chore's point value.</li>
          <li>If desired, each child can have a different incomplete chore reduction value.</li>
          <li>If a child completes a chore, the point reduction is removed for that chore even before it is rated.</li>
          <li>If you do not rate chores or bonus tasks during one day, you can always go back and rate them later.</li>
        </ul>
        <h3>Paydays</h3>
        <p>
          Earned points are not automatically awarded to the child in this system.
          There are pros and cons to this approach, but overall it allows for corrections for incorrect or
          incomplete chores and ratings. It also teaches the life lesson of delayed gratification just as
          most real-world jobs send out paychecks every couple weeks.
        </p>
        <ul>
          <li>Points are officially awarded to each child at most once per day.</li>
          <li>Paydays award points from one or more previous days.</li>
          <li>Paydays are a good time to meet with children and quickly discuss how they're doing overall.</li>
        </ul>
        <div style={{maxWidth: 400}}>
          <IndexImages image="payday_dialog" />
        </div>
        <h3>Chore Strategies</h3>
        <p>
          We do chores to maintain and sometimes slightly improve on existing conditions.
          Chores are not supposed to make radical changes to existing conditions.
          Chores include tasks that must be properly completed or the household, parent,
          or child will have negative consequences in some form, either now or in the future.
          We want to motivate each child to complete every assigned chore, every single day!
          If the goal is not to have 100% chore completion every day, chores may need a re-evaluation,
          and some chores might be better converted to bonus tasks.
        </p>
        <p>
          Setting the right point value to each chore is mostly likely going to be guesswork at first, but through trial and error,
          point values can be determined to get the right balance of work to reward. When creating a chore,
          the point value is set automatically to the number of minutes the chore is estimated to take, however,
          the default value can be overridden easily. For example, a more challenging chore like edging the lawn may
          be worth many more points than the number of minutes it takes to complete it because it takes more skill than a chore like feeding the pet.
        </p>
        <h3>Chore Rotation</h3>
        <p>
          If done right, good chore rotation can prevent frustration for both the parent and child.
          Bright Fox Home has the option to "force rotate" chores between those who can complete the chores.
          It's useful to enable the "force exact rotation" option on chores that are more demanding than other chores.
        </p>
        <ul>
          <li>Turn on "Force Exact Rotation" on as many chores that require perfect rotation.</li>
          <li>Forced rotation looks at the people available for the chore, the scheduled dates, and the start date of the chore.</li>
          <li>If chores must rotate to different people on the same schedule, set the chore start date to be different.</li>
        </ul>
        <div style={{maxWidth: 300}}>
          <IndexImages image="force_exact_rotation" />
        </div>
        <h3>Bonus Point Strategies</h3>
        <p>
          Bonus points are best used for incentivizing a behavior or completion of an extra task that is above and beyond the ordinary.
          Bonus tasks should not be expected to be completed regularly (or ever), as they are just bonuses.
          The strategy of pointing bonus points is a little less critical compared to chores and rewards, but high-point
          tasks will have a better chance of being completed than lower-point tasks.
        </p>
        <p>
          It's a good idea to review the bonus tasks every once-in-a-while to determine if any of the bonus tasks should
          be moved to be a chore because it needs to get done.  An alternative is to increase the point value of the bonus task
          to increase the chances of a child wanting to go above and beyond.
        </p>
        <h3>Reward Strategies</h3>
        <p>
          Depending on the child, rewards can be the most important motivator for completing chores and bonus tasks.
          Because most people (including children) do not like doing chores, they need a good reason to do them and do them well.
        </p>
        <p>
          Rewards can be simple, or they can be extravagant.
          However, the simpler it is to fulfill the reward, the better - as long as it's something the child actually wants.
        </p>
        <h3>Bulk Rewards</h3>
        <p>
          As in real-world markets, purchasing higher quantities of a product or service decreases the
          average unit price. We can give children the same options to pay for bigger rewards
          at a bigger discount if they are able to save their points longer without spending them.
        </p>
        <p>
          For example, you could create 3 rewards for currency:
          100 points for $1.00, 190 points for $2.00, and 270 points for $3.00. In this example, the child
          would receive a 5% discount if buying $2.00 or a 10% discount if buying $3.00 compared with the $1.00 reward.
        </p>
        <h3>Reward Restrictions</h3>
        <p>
          The system has a few options for making sure chores are first priority - as we want to keep the 100% chore
          completion goal in mind.
        </p>
        <p>
          Reward redemption can be restrcted on the following conditions:
        </p>
        <ul>
          <li>A child's chore status being green</li>
          <li>A child's chore status being yellow or better</li>
          <li>All household chores with the child's name on it rated at 4.5-6 stars (green)</li>
          <li>All household chores with the child's name on it rated at 2.5-6 stars (yellow or better)</li>
          <li>A logical AND combination of any of the above conditions</li>
        </ul>
        <p>
          The default settings are that the child's chore status must be green before allowing reward redemption.
          That setting can be changed for all children or have different settings for each child.
        </p>
        <div style={{maxWidth: 475}}>
          <IndexImages image="redeem_rewards_conditions" />
        </div>
        <h3>Bonus Task Restrictions</h3>
        <p>
          Just like rewards, it is possible to restrict completing a bonus task.
          The same conditions apply as for reward restrictions, and the default settings
          can be overridden for all or specific children. The default setting is that
          there are no restrictions before a child can complete a bonus task.
        </p>
        <div style={{maxWidth: 475}}>
          <IndexImages image="do_bonus_tasks_conditions" />
        </div>
        <h3>Rating Strategies</h3>
        <p>
          When a chid completes a chore, many times the child will want an immediate chore rating so they
          can see their progress towards getting into green or yellow chore status. This can be a
          frustration for the parent because rating a chore interrupts the parent's workflow throughout
          different parts of the day. A parent will usually want to rate chores in batches (ie. a few or all chores at the same time)
          in order to save time and effort overall.
        </p>
        <p>
          It's important to set expectations for the child and let them know how long it will
          take before they will rate chores. It's a different situation with each child's personality,
          and care must be taken to protect the parent's time while also helping the child by rating
          their complete chores.
        </p>
        <p>
          Rating chores is an important teaching moment to help correct issues with a chore.
          Each child should to learn how to take pride in their work - even if it's just a daily chore.
        </p>
        <p>
          If a chore is rated with fewer than 6 stars (100%), the child will have the opportunity to
          fix the problems reported by the parent while rating and re-complete the chore.
          If a child re-completes the chore, points on that chore will reset back to zero as if it were completed for the first time.
          The parent will then need to re-rate the chore, and provide additional feedback to the child.
        </p>
      </div>
    </div>
  </Layout>
)

export default IndexPage
