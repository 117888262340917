import React from 'react'

const Pricing = () => (
  <div style={{display: 'inline-block',}}>
    <div style={{
      width: '300px',
      borderRadius: '10px',
      boxShadow: '10px 10px 10px rgba(0, 0, 0, .5)',
      border: '1px solid rgba(0,0,0, 0.7)',
      backgroundColor: '#444',
      display: 'inline-block',
      margin: '1rem',
    }}>
      <div style={{
        color: '#fff',
        borderTopLeftRadius: '10px',
        borderTopRightRadius: '10px',
        padding: '0.5em',
        fontSize: '1.5em',
        textAlign: 'center',
      }}>
        The Good Plan
      </div>
      <hr style={{
        color: '#000',
        height: '1px',
        margin: 0,
      }}/>
      <div style={{
        padding: '0.5em',
        color: '#fff',
        textAlign: 'center',
      }}>
        <div style={{
          fontSize: '3em',
        }}>
          Free*
        </div>
        <div style={{
          color: '#FEE16C',
          padding: '0.5em',
        }}>
          The best and only plan
        </div>
        <div style={{
          color: '#999',
          padding: '0.5rem',
        }}>
          You pay nothing. BFH is gratis.
          <div style={{fontSize: '0.8rem', marginTop: '1em'}}>* Super free</div>
        </div>
      </div>
      <div style={{
        color: '#999',
        backgroundColor: '#ccc',
        padding: '0.5rem',
        textAlign: 'center',
      }}>
        <ul className="item-list">
          <li>Chores galore</li>
          <li>Bonus tasks for points</li>
          <li>Reward gratification</li>
          <li>Red/Yellow/Green daily thresholds</li>
          <li>Virtual bank account</li>
          <li>Simple webhooks for rewards</li>
          <li>Shared household chores</li>
          <li>Auto-assignments for skill and load</li>
        </ul>
      </div>
      <a style={{
        backgroundColor: '#FEE16C',
        padding: '0.5em',
        borderBottomLeftRadius: '10px',
        borderBottomRightRadius: '10px',
        borderTop: '1px solid rgba(0,0,0, 0.7)',
        textAlign: 'center',
        color: '#444',
        fontSize: '1.2em',
        display: 'block',
        textDecoration: 'none',
      }}
      href="https://app.brightfoxhome.com/signup"
      >
        Get a free account
      </a>
    </div>
  </div>
)

export default Pricing
